document.addEventListener('turbolinks:load', () => {
  function copy(buttonElementId, targetInputId) {
    const target = document.getElementById(buttonElementId)
    if (target) {
      target.addEventListener('click', () => {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(document.getElementById(targetInputId).value);
        }
      })
    }
  }

  copy('copy-link-button', 'link-value')
  copy('copy-link-m-button', 'link-m-value')
  copy('copy-iframe-button', 'iframe-value')
  copy('copy-iframe-m-button', 'iframe-m-value')
  copy('copy-module-guestbook-data-button', 'showcase_module_guestbook_data')
  copy('copy-showcase-editor-data-button', 'showcase_showcase_editor_data')
})
